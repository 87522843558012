import { useRouteError } from "react-router-dom";
import React from "react";
import RouterUtility from "../../Utilites/RouterUtils";

export default function ErrorPage() {
  const routerUtility = new RouterUtility();
  const error:any = useRouteError();
  console.error(error);
  console.log(routerUtility.location.pathname);

  return (
    <nav className="flex flex-col h-full w-full justify-center align-center items-center text-center bg-black">
      <div className="flex flex-col h-full w-full justify-center align-center items-center text-center">
        <a id="error-page" href={"/Home"}>
          <h1>Oops!, an unexpected error has occurred:</h1>
          <p>
            <i>{error && (error.statusText || error.message)? error.statusText || error.message: "Error!"}</i>
          </p>
          <div className="bg-red-500 text-white border shadow rounded-md p-4" >
            <p>Return to Home page</p>
          </div>
        </a>
      </div>
    </nav>
  );
}

