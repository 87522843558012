import Slideover from "./SlideOver"
import { useState, useEffect} from "react";
import React, {useRef} from "react";
import clsx from "clsx";
import { blogPost, UserAccount} from "../../Utilites/Decoders";
import { activeDomain, defaultBlogpost } from "../../Utilites/Globals";
import { VscAdd, VscBug, VscClose } from "react-icons/vsc";
import {BsDoorClosed, BsX} from "react-icons/bs";
import { useAuth } from "../../Utilites/Authprovider";
import { fireDb, fireStore } from "../../Utilites/firebase";
import { ref as storeRef, uploadBytes, UploadResult } from "firebase/storage";
import { onValue, ref, getDatabase, set, push } from "firebase/database";
import { createRoot } from "react-dom/client";
import { Stage, Layer, Image} from "react-konva";
import useImage from "use-image";
import uuid from "react-uuid";
import RouterUtility from "../../Utilites/RouterUtils";

export const NewBlogPostForm = ({
  inBlogPost,
  onClose,
  onSubmit,
  sourcePath
}: {
  inBlogPost: blogPost;
  onClose: () => void;
  onSubmit: () => void | null;
  sourcePath?: string | null;
}) => {
  const [openNodes, setOpenNodes] = useState<{
    profileDetails: boolean;
    notifications: boolean;
    prefrences: boolean;
    debug: boolean;
  }>({
    profileDetails: false,
    notifications: true,
    prefrences: false,
    debug: false,
  });
  const { user } = useAuth();
  const currentReactAccount = user;
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [newBlogPost, setNewBlogPost] = useState<blogPost>(inBlogPost);
  const [loading, setLoading] = useState<boolean>(false);
  const routerUtility = new RouterUtility();

  //save the new image to firebase
  async function uploadImage(image: File) {
    if (user) {
      //if user is logged in
      var storageRef = storeRef(
        fireStore,
        `${activeDomain}/images/${sourcePath? sourcePath: "blogPosts"}/${user.uid}/${uuid()}`
      );
      // Create a reference to the location in the storage bucket
      var uploadedImg = await uploadBytes(storageRef, image)
        .then((snapshot) => {
          console.log("Uploaded a blob or file!", snapshot);
          return snapshot;
        })
        .catch((e) => {
          console.log("upload image failed");
          return null;
        });
    } else {
      var storageRef = storeRef(
        fireStore,
        `${activeDomain}/images/anonymous/${uuid()}`
      );
      // Create a reference to the location in the storage bucket
      var uploadedImg = await uploadBytes(storageRef, image)
        .then((snapshot) => {
          console.log("Uploaded a blob or file!", snapshot);
          return snapshot;
        })
        .catch((e) => {
          console.log("upload image failed");
          return null;
        });
    }

    return uploadedImg;
  }

  //save blogpost to firebase, converts content to storage path via upload img
  async function postToFireBase(newPost: blogPost) {
    setLoading(true);

    const newPostContent = newPost.content;
    //upload associated image
    if (newPostContent instanceof File) {
      let contentStored = await uploadImage(newPostContent)
        .then((snapshot) => {
          console.log("upload blog post content complete!", snapshot);
          newPost.content = snapshot?.ref.fullPath
            ? snapshot.ref.fullPath
            : "err"; //change new post content from file to file path
          return snapshot;
        })
        .catch((e) => {
          console.log("upload blog post content failed", e);
          return null;
        });

      if (!contentStored) {
        console.log("failed to store content, opting out");
        setLoading(false); //update for better response display
        return null;
      }
    }

    try {
      //push new key
      var fbPostUid = push(ref(fireDb, sourcePath? sourcePath: "blogPosts")).key; //get uuid from firebase
      newPost.key = fbPostUid;
      //set key value
      set(ref(fireDb, `${activeDomain}/${sourcePath? sourcePath: "blogPosts"}/${fbPostUid}`), newPost) //save new post under uuid
        .then((e) => {
          console.log("post to firebase Success!", e);
          setLoading(false);
        })
        .catch((e) => {
          console.log("post to firebse fail!", e);
          setLoading(false);
        });
    } catch (e) {
      setLoading(false);
      console.log("post to firebase Failed 2", e);
    }
  }

  return (
    <div className="h-full overflow-hidden">
      <div className="flex flex-col h-full w-full bg-gray-800 p-2 overflow-hidden justify-between">
        <div className="flex flex-col h-full w-full space-y-2">
          {/* title */}
          <div className="w-full">
            <label
              htmlFor="Title"
              className={clsx(
                "block text-sm font-medium leading-5 flex flex-row",
                newBlogPost.title !== "" &&
                  newBlogPost.title !== defaultBlogpost.title
                  ? "text-green-500"
                  : "text-red-500"
              )}
            >
              Title:
            </label>
            <div className="relative rounded-md shadow-sm">
              <input
                id="username"
                type="text"
                autoComplete="false"
                className="form-input block w-full sm:text-sm sm:leading-5 p-2 rounded-md border"
                value={newBlogPost.title}
                onChange={(e) =>
                  setNewBlogPost({ ...newBlogPost, title: e.target.value })
                }
              />
            </div>
          </div>

          {/* Author */}
          <div className="w-full">
            <label
              htmlFor="Title"
              className={clsx(
                "block text-sm font-medium leading-5 flex flex-row",
                newBlogPost.author !== "" &&
                  newBlogPost.author !== defaultBlogpost.author
                  ? "text-green-500"
                  : "text-red-500"
              )}
            >
              Author:
            </label>
            <div className="relative rounded-md shadow-sm">
              <input
                id="username"
                type="text"
                autoComplete="false"
                className="form-input block w-full sm:text-sm sm:leading-5 p-2 rounded-md border"
                value={newBlogPost.author}
                onChange={(e) =>
                  setNewBlogPost({ ...newBlogPost, author: e.target.value })
                }
              />
            </div>
          </div>

          {/* content */}
          <div className="w-full">
            <label
              htmlFor="Title"
              className={clsx(
                "block text-sm font-medium leading-5 flex flex-row",
                newBlogPost.content !== null &&
                  newBlogPost.content !== defaultBlogpost.content
                  ? "text-green-500"
                  : "text-red-500"
              )}
            >
              Content:
            </label>
            <div className="relative rounded-md shadow-sm text-white font-medium">
              <input
                id="username"
                type="file"
                autoComplete="false"
                className="form-input block w-full sm:text-sm sm:leading-5 p-2 rounded-md border"
                onChange={(e) =>
                  e.target.files
                    ? setNewBlogPost({
                        ...newBlogPost,
                        content: e.target.files[0],
                      })
                    : null
                }
              />
            </div>
          </div>

          {/* Author */}
          <div className="w-full">
            <label
              htmlFor="Title"
              className={clsx(
                "block text-sm font-medium leading-5 flex flex-row",
                newBlogPost.description !== "" &&
                  newBlogPost.description !== defaultBlogpost.description
                  ? "text-green-500"
                  : "text-red-500"
              )}
            >
              Description:
            </label>
            <div className="relative rounded-md shadow-sm">
              <textarea
                id="username"
                autoComplete="false"
                className="form-input block w-full sm:text-sm sm:leading-5 p-2 rounded-md border"
                value={newBlogPost.description}
                onChange={(e) =>
                  setNewBlogPost({
                    ...newBlogPost,
                    description: e.target.value,
                  })
                }
              />
            </div>
          </div>
          {loading ? (
            <div className="flex flex-row w-full justify-end space-x-2 text-white">
              <p>"Loading..."</p>
            </div>
          ) : (
            <div className="flex flex-row w-full justify-end space-x-2 text-white">
              <button
                className="button hover:text-green-500 font-medium p-2 flex flex-row space-x-2 text-center items-center rounded-md border shadow-md"
                onClick={() => postToFireBase(newBlogPost)}
              >
                <p>Submit</p>
                <VscAdd size={24} />
              </button>

              <button
                className="button hover:text-red-500 font-medium p-2 flex flex-row space-x-2 text-center items-center rounded-md border shadow-md"
                onClick={() => onClose()}
              >
                <p>Cancel</p>
                <VscClose size={24} />
              </button>
            </div>
          )}
        </div>

        {/* debug */}
        <div
          className={clsx(
            "flex flex-col w-full divide-y space-y-2 px-3 py-2 text-white",
            openNodes.debug ? "bg-gray-600 rounded-md shadow-inner" : "border-b"
          )}
        >
          <button
            className={clsx(
              "w-full button font-medium flex flex-row justify-between",
              openNodes.debug
                ? "text-yellow-500 hover:text-yellow-700"
                : "hover:text-yellow-500"
            )}
            onClick={() =>
              setOpenNodes({ ...openNodes, debug: !openNodes.debug })
            }
          >
            <p>Debug</p>
            {openNodes.debug ? <VscClose size={24} /> : <VscBug size={24} />}
          </button>
          {openNodes.debug ? (
            <p className="whitespace-pre font-mono text-xs text-left truncate w-full">
              {JSON.stringify(
                {
                  currentEmail: currentReactAccount?.email,
                  currentuuid: currentReactAccount?.uid,
                  currentDisplayname: currentReactAccount?.displayName,
                  blogPost: newBlogPost,
                },
                null,
                2
              )}
            </p>
          ) : null}
        </div>
      </div>
    </div>
  );
};

const BlogPostSlideover = (
  {
    isOpen,
    onClose,
    onSubmit,
    sourcePath
  }: {
    onClose: () => void
    onSubmit: () => void|null
    isOpen: blogPost|null
    sourcePath?: string|null
  }
) => {
  const { user } = useAuth();
  return isOpen !== null ? (
    <Slideover
      title={isOpen === defaultBlogpost? `New ${sourcePath? sourcePath: "Post"}!` : `Editing ${sourcePath? sourcePath: "Post"}: ${isOpen.title}`}
      titleColor={user?"bg-gray-600 border-b":"bg-red-500"}
      isOpen={isOpen !== null}
      onClose={onClose}
      
    >
      {({ onClose }) => (
        <NewBlogPostForm onClose={onClose} onSubmit={onSubmit} inBlogPost={isOpen} sourcePath={sourcePath}/>
      )}
    </Slideover>
  ) : null;
};

export default BlogPostSlideover;