import React from "react";
// import { useState } from "react";
import { BsDoorOpen } from "react-icons/bs";

const InactivePage = (
  login: {
    login: (e:any) => void
  }
) => {
  return (
    <div className="flex flex-col h-full w-full bg-black">
      <div className="flex flex-row py-4 px-6 items-center bg-white shadow justify-center">
        <button
          onClick={(e) => login.login(true)}
          className={
            "text-white hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 focus:ring-offset-slate-50 font-semibold p-2 rounded-lg w-full flex items-center sm:w-auto shadow-md border bg-sky-500"
          }
        >
          <article className="prose lg:prose-xl text-white">
            <h3 className="text-white pb-0">Sign in</h3>
          </article>
          <BsDoorOpen size={48} />
        </button>
      </div>
    </div>
  );
};

export default InactivePage;
