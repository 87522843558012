import { useState, useEffect, useMemo, useRef } from "react";
import { activeDomain, defaultBlogpost } from "../../Utilites/Globals";
import BlogPostSlideover from "../SlideOvers/blogPostSlideover";
import uuid from "react-uuid";
import { blogPost, SifterData } from "../../Utilites/Decoders";
import { fireDb, fireStore } from "../../Utilites/firebase";
import { onValue, ref } from "firebase/database";
import { useAuth } from "../../Utilites/Authprovider";
import { ref as storeRef, getDownloadURL } from "firebase/storage";
import { createRoot } from "react-dom/client";
import { Stage, Layer, Star, Text, Circle, Group } from "react-konva";
import clsx from "clsx";
import {
  BiArchive,
  BiArrowToLeft,
  BiArrowToRight,
  BiCircle,
  BiErrorCircle,
  BiInfoCircle,
  BiPauseCircle,
  BiPlayCircle,
  BiRewindCircle,
} from "react-icons/bi";
import { BsFillCircleFill } from "react-icons/bs";
import SpiderwebChart from "./sifterGraph";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HCMore from "highcharts/highcharts-more";
import HighchartsExporting from "highcharts/modules/exporting";
import streamGraph from "highcharts/modules/streamgraph";
import RouterUtility from "../../Utilites/RouterUtils";
//import areaGraph from "highcharts/modules/ar"
streamGraph(Highcharts);
HCMore(Highcharts);
HighchartsExporting(Highcharts);

var sortBy = require("sort-by");
//move carosel to new component asap**

//featured
//new
//favorites
//projects
//  [f-]
//  n--+
//  f--+
//  p--+
//  +--x

//needs methods for editing and deleting posts
//gallery page ->
//  grab list of gallery tabs (blogpost source names)
//  map gallery tabs to carrousels
//    maybe neccassary to keep a seperate list of gallery tabs (per domain name)

const Caroseul = ({
  sourcePath,
  sourceFilter
}: {
  sourcePath?: string|null
  sourceFilter?: ((e:any) => boolean)|null
}) => {
  const [blogPosts, setBlogPosts] = useState<blogPost[]>([]);
  const [editingBlogPost, setEditingBlogPost] = useState<blogPost | null>(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const carouselRef = useRef<HTMLDivElement>(null);
  const routerUtility = new RouterUtility();

  const prevItem = () => {
    setCurrentIndex((currentIndex - 1 + blogPosts.length) % blogPosts.length);
  };

  const selectItem = (index: number) => {
    setCurrentIndex(index);
  };

  const nextItem = () => {
    setCurrentIndex((currentIndex + 1) % blogPosts.length);
  };

  useEffect(() => {
    if (carouselRef.current) {
      carouselRef.current.scrollLeft =
        currentIndex * (carouselRef.current.offsetWidth / blogPosts.length);
    }
  }, [currentIndex]);

  useEffect(() => {

    //fetch content combines the blogposts with their associated images
    const FetchContent = async (temp: blogPost[]) => {
      try {
        //let temp = inBlogPosts;        
        console.log(`raw: ${sourcePath? sourcePath: "all"}`, temp);
        if(!sourcePath){
          temp = temp.flatMap((obj) => Object.values(obj)).flat();
        }

        for (var x = 0; x < temp.length; x++) {
          if (typeof temp[x].content === "string") {
            const retrievedContent = await getDownloadURL(
              storeRef(fireStore, temp[x].content as string)
            );
            temp[x].content = retrievedContent;
          }
        }
        console.log(`comp: ${sourcePath ? sourcePath : "all"}`, temp);
        if(sourceFilter){
          setBlogPosts(temp.filter(x => sourceFilter(x)));
        }else{
          setBlogPosts(temp);
        }
      } catch (e) {
        console.log("fetch blog post content failed!", e);
      }
    };

    const connectionPath = ref(fireDb, `${activeDomain}${sourcePath? "/"+sourcePath: ""}`);
    return onValue(connectionPath, (snapshot) => {
      const data = snapshot.val();
      console.log(data);
      if (snapshot.exists()) {
        let temp = Object.values(data).map((x) => x as blogPost);
        if (temp.length > 0) {
          FetchContent(temp);
          //setBlogPosts(temp);
        }
      }
    });
  }, []);

  function handleScroll(e: any) {
    if (carouselRef.current) {
      const delta = e.deltaY;
      const scrollStep = 100;
      carouselRef.current.scrollLeft += delta > 0 ? scrollStep : -scrollStep;
    }
  }

  return (
    <div className="relative">
      <BlogPostSlideover
        isOpen={editingBlogPost}
        onClose={() => setEditingBlogPost(null)}
        onSubmit={() => console.log("profile submitted: ")}
        sourcePath={sourcePath? sourcePath: "blogPosts"}
      />

      {/* row title / tools */}
      <div className="">
        <div className="flex flex-row w-full justify-between py-1 overflow-hidden border-b ">
          <p className="px-2 text-large text-white">{sourcePath? sourcePath: "All"}</p>

          <button
            key={`${uuid()}`}
            className="rounded-md text-center text-white hover:bg-slate-700 shadow-md w-8"
            onClick={() => setEditingBlogPost(defaultBlogpost)}
          >
            <p className="w-full grow">+</p>
          </button>
        </div>
      </div>

      {/* row content */}
      <div className="relative p-2">
        <div
          className="flex overflow-x-auto space-x-2 overscroll-none"
          ref={carouselRef}
          onWheel={(e) => handleScroll(e)}
        >
          {blogPosts.map((item, index) => (
            <div
              key={`${uuid()}`}
              className={clsx(
                "bg-gray-300 w-1/4 md:w-72 flex-shrink-0 relative rounded-md shadow overflow-hidden",
                index === currentIndex ? "opacity-100" : "opacity-50"
              )}
              onClick={() => selectItem(index)}
            >
              <div className="aspect-[9/12]">
                <img
                  src={item.content}
                  alt={item.title}
                  className="object-contain object-center w-full h-full"
                />
              </div>
            </div>
          ))}
        </div>

        <button
          className="absolute top-1/2 left-5 transform -translate-y-1/2 -translate-x-full p-2 rounded-full focus:outline-none"
          onClick={prevItem}
          aria-label="Previous item"
        >
          <BiArrowToLeft className="h-5 w-5 text-amber-500 hover:text-green-500 shadow-md " />
        </button>
        <button
          className="absolute top-1/2 right-5 transform -translate-y-1/2 translate-x-full p-2 rounded-full focus:outline-none"
          onClick={nextItem}
          aria-label="Next item"
        >
          <BiArrowToRight className="h-5 w-5 text-green-500 hover:text-amber-500  shadow-md " />
        </button>
      </div>
    </div>
  );
};

const Home = () => {
  const { user } = useAuth();
  const [liveData, setLiveData] = useState<SifterData[]>([]);
  const [sessionAvg, setSessionAvg] = useState<SifterData[]>([]);
  const [play, setPlay] = useState<boolean>(false);
  const chartRef = useRef<any>();
  const [massData, setMassData] = useState<SifterData[]>([]);

  const [latest, setLatest] = useState<any>({
    chart: {
      type: "area",
    },
    title: {
      text: "Live Sensor Feed",
      align: "left",
    },
    subtitle: {
      text: "PM2.5 Plantower AQ Sensor",
      align: "left",
    },
    yAxis: {
      title: {
        text: "TWh",
      },
    },
    series: [],
  });

  useEffect(() => {
    if (massData && massData.length > 0) {
      //console.log("new mass", massData);
      let ts = Object.keys(massData[0])
        .filter((x) => x.includes("part"))
        .map((x) => ({
          name: x,
          data: [massData[0][x]],
        }));

      massData.forEach((item, i) => {
        Object.entries(item).forEach(([key, value]) => {
          const seriesIndex = ts.findIndex((series) => series.name === key);
          if (seriesIndex != -1 && key.includes("part")) {
            ts[seriesIndex].data.push(value);
          }
        });
      });

      //console.log(ts);
      setLatest({
        ...latest,
        series: ts,
        xAxis: {
          categories: massData.map((x) => x.count),
        },
      });
    }
  }, [massData]);

  const useMass = useMemo(() => latest, [latest]);

  useEffect(() => {

    const updateTable = async (temp: SifterData) => {
      try {
        let final = liveData;
        final.push(temp);
        if (final.length > 16) {
          final.shift();
        }
        setLiveData([...final]);
      } catch (e) {
        console.log("fetch Live data failed!", e);
      }
    };

    const connectionPath = ref(fireDb, "/sifter_live");
    return onValue(connectionPath, (snapshot) => {
      const data = snapshot.val();

      if (snapshot.exists()) {
        const temp = data as SifterData;
        if (temp) {          
          //console.log("test", temp);
          //console.log(massData);
          updateTable(temp);
          setMassData((prevMassData) => [...prevMassData, temp]);
        }
      }
    });
  }, []);

  // function handleScroll(e:any) {
  //   if(carouselRef.current){
  //     const delta = e.deltaY;
  //     const scrollStep = 100;
  //     carouselRef.current.scrollLeft += delta > 0 ? scrollStep : -scrollStep;
  //   }
  // }

  return (
    <div className="relative bg-black space-y-1 overflow-y-auto overflow-x-hidden">
      {/* <BlogPostSlideover
        isOpen={editingBlogPost}
        onClose={() => setEditingBlogPost(null)}
        onSubmit={() => console.log("profile submitted: ")}
      />

      row title / tools
      <div className="px-2">
        <div className="flex flex-row w-full justify-between py-1 overflow-hidden border-b ">
          <p className="text-large text-white">Latest</p>

          <button
            key={`${uuid()}`}
            className="rounded-md text-center text-white hover:bg-slate-700 shadow-md w-8"
            onClick={() => setEditingBlogPost(defaultBlogpost)}
          >
            <p className="w-full grow">+</p>
          </button>
        </div>
      </div>
      row content
      <div className="relative px-2">
        <div
          className="flex overflow-x-auto space-x-2"
          ref={carouselRef}
          onWheel={(e) => handleScroll(e)}
        >
          {blogPosts.map((item, index) => (
            <div
              key={index}
              className={clsx(
                "bg-gray-300 w-1/4 md:w-72 flex-shrink-0 relative rounded-md shadow overflow-hidden",
                index === currentIndex ? "opacity-100" : "opacity-50"
              )}
              onClick={() => selectItem(index)}
            >
              <div className="aspect-[9/12]">
                <img
                  src={item.content}
                  alt={item.title}
                  className="object-contain object-center w-full h-full"
                />
              </div>
            </div>
          ))}
        </div>

        <button
          className="absolute top-1/2 left-5 transform -translate-y-1/2 -translate-x-full p-2 bg-white shadow-md rounded-full hover:bg-gray-100 focus:outline-none"
          onClick={prevItem}
          aria-label="Previous item"
        >
          <BiArrowToLeft className="h-5 w-5" />
        </button>
        <button
          className="absolute top-1/2 right-5 transform -translate-y-1/2 translate-x-full p-2 bg-white shadow-md rounded-full hover:bg-gray-100 focus:outline-none"
          onClick={nextItem}
          aria-label="Next item"
        >
          <BiArrowToRight className="h-5 w-5" />
        </button>
      </div> */}

      <Caroseul
        sourcePath={"blogPosts"}
      />
      <Caroseul
        sourcePath={"Projects"}
      />
      <Caroseul
        sourcePath={"Art"}
      />
      <Caroseul
        sourcePath={"Work"}
      />
      <Caroseul />

      {/* row title / tools */}
      <div className="px-2">
        <div className="flex flex-row w-full justify-between py-1 overflow-hidden">
          <p className="text-large text-white">Live Data</p>
        </div>
      </div>

      {/* row title / tools */}
      <div className="flex flex-col md:flex-row w-full overflow-hidden divide-y md:divide-x border-y ">
        <div className="flex flex-row md:flex-col px-2 py-1 space-x-2 md:space-x-0 md:space-y-2">
          <button
            key={`${uuid()}`}
            className="shadow-md w-8 rounded-md p-1 bg-emerald-900"
            onClick={() => setPlay(!play)}
          >
            {play ? (
              <BiPauseCircle
                size={24}
                className="text-green-500 hover:text-amber-500 "
              />
            ) : (
              <BiPlayCircle
                size={24}
                className="text-amber-500 hover:text-green-500"
              />
            )}
          </button>

          <button
            key={`${uuid()}`}
            className="shadow-md w-8 rounded-md p-1 bg-emerald-900"
            onClick={() => null}
          >
            <BiArchive
              size={24}
              className="text-amber-500 hover:text-green-500"
            />
          </button>
        </div>

        <div className="flex flex-col flex-1 grow w-full border-b divide-y">
          <div className="flex flex-row divide-x">
            {liveData.length > 0
              ? Object.keys(liveData[0]).map((y) =>
                  y === "count" || y.includes("parti") ? (
                    <p
                      key={`${uuid()}`}
                      className="p-1 flex-1 whitespace-pre font-mono text-xs text-left truncate w-full text-white"
                    >
                      {y.replace("particles", "pt").replace("count", "sIdx")}
                    </p>
                  ) : null
                )
              : null}
          </div>

          {liveData.length > 0 ? (
            liveData.sort(sortBy("-count")).map((x, i) => (
              <div key={i} className="flex flex-row divide-x overflow-hidden">
                {Object.keys(x).map((y, j) =>
                  y === "count" || y.includes("parti") ? (
                    <p
                      key={j}
                      className="p-1 flex-1 whitespace-pre font-mono text-xs text-left truncate w-full text-white"
                    >
                      {x[y]}
                    </p>
                  ) : null
                )}
              </div>
            ))
          ) : (
            <p className="whitespace-pre font-mono text-xs text-left truncate w-full text-white">
              No live Data
            </p>
          )}
        </div>

        <div className="flex flex-col flex-1 overflow-hidden divide-x border-y text-white text-center">
          <p className="flex-1">{new Date().toLocaleString()}</p>
          {latest != null ? (
            <HighchartsReact
              highcharts={Highcharts}
              options={useMass}
              ref={chartRef}
            />
          ) : null}
        </div>
      </div>

      <div className="flex flex-row w-full h-1/4 overflow-hidden">
        <p className="invisible h-full w-full">hi...</p>
      </div>
    </div>
  );
};

export default Home;
