import React from "react";
import { blogPost, MODE_D, UserAccount } from "../Utilites/Decoders";
import InactivePage from "../Pages/Main/InactivePage";
import { createBrowserHistory } from "@remix-run/router";
import Home from "../Pages/Content/Home";
export const connectionStringBase = "Target Api/"

export const websiteTitle: string = "CRittirsCreations"
export const websiteRoot: string = "/";
export const activeDomain: string = window.location.hostname !== "localhost"? (window.location.hostname).split('.')[0] : "crittirscreations";

export const defaultBlogpost: blogPost= {
  title: "new blog post",
  description: "enter a breif desription of this post",
  author: "default",
  authorId:"default",
  content: null,
  key: null
};

export const Webpages: MODE_D[] = [
  // {
  //   ID: 0,
  //   permissionLevel: 0,
  //   mode: "Inactive",
  //   path: ``,
  //   element: (
  //     <InactivePage login={(e: any) => console.log("login:", e, "inactive")} />
  //   ),
  //   active: false,
  // },
  {
    ID: 0,
    permissionLevel: 0,
    mode: "Inactive",
    path: `/`,
    element: (
      <InactivePage
        login={(e: any) => console.log("login:", e, "inactive /")}
      />
    ),
    active: false,
  },
  {
    ID: 1,
    permissionLevel: 0,
    mode: "Home",
    path: `/Home`,
    element: (
      <Home />
    ),
    active: true,
  },
  {
    ID: 3,
    permissionLevel: 1,
    mode: "Contact Page",
    path: `/Contact`,
    element: (
      <InactivePage login={(e: any) => console.log("login:", e, "contact")} />
    ),
    active: true,
  },
];

export const formInputTypes = () => {};

export const OP_STATUS = ["Current", "Upcoming", "Future", "Planned"]; //used in filtering
