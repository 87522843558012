import React from "react";
import "./App.css";
import { RecoilRoot } from "recoil";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./index.css";
import Root from "./Pages/Main/root";
import ErrorPage from "./Pages/Main/error-page";
import { Webpages} from "./Utilites/Globals";
import { AuthProvider } from "./Utilites/Authprovider";


//url rewrite
//https://www.youtube.com/watch?v=hkEFPzixiVE

const defaultLoader = () => {
  return {};
};
//oldHome "https://CRittirsCreations.github.io/MasterWebsite"
const router = createBrowserRouter(
  [
    {
      path: "/",
      element: <Root />,
      errorElement: <ErrorPage />,
      loader: defaultLoader,
      children: Webpages.map((page) => ({
        path: page.path,
        element: page.element,
        loader: defaultLoader,
      })),
    },
  ],
  {
    // baseurl?
    //basename: "/MasterWebsite",
  }
);

function App() {
  return (
    <div className="App flex flex-col overflow-hidden">
      <AuthProvider>
        <RecoilRoot>
          {/* <StartingMenu /> */}
          <RouterProvider router={router} />
        </RecoilRoot>
      </AuthProvider>
    </div>
  );
}

export default App;
