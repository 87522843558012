import React, { useMemo } from "react";
import { Outlet, matchPath } from "react-router";
import { useState, useEffect } from "react";
import { Webpages, websiteTitle } from "../../Utilites/Globals";
import { BiUserCircle, BiArrowFromLeft, BiX } from "react-icons/bi";
import { useRecoilState } from "recoil";
import { CurrentUser, ReactUserAccount } from "../../Utilites/Atoms";
import UserProfileSlideover from "../SlideOvers/UserProfileSlideover";
import clsx from "clsx";
import uuid from "react-uuid";
import localforage from "localforage";
import { UserAccount } from "../../Utilites/Decoders";
import RouterUtility from "../../Utilites/RouterUtils";
import { BsMenuButtonFill } from "react-icons/bs";
import {AiFillLayout} from "react-icons/ai"
import { User, UserCredential } from "firebase/auth";
import { useAuth } from "../../Utilites/Authprovider";

const Root = () => {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [openUserProfile, setOpenUserProfile] = useState<boolean>(false);
  const [openMenu, setOpenMenu] = useState<boolean>(false);
  const routerUtility = new RouterUtility();
  const {user} = useAuth();

  const loadUser = async (act:User|null) => {  
    console.log("current User", act);
    
    if (act) {
      setCurrentUser(act);   
      const lastPage = await routerUtility
        .getLocal("lastPage", false)
        .then((e) => (e !== null ? e : "/Home"));

        console.log("clearing localstorage WA");
        localforage.clear();

      if ((routerUtility.location.pathname as string) === "/") {
        routerUtility.navigateTo(lastPage, null, { replace: true });
      }
    } else {
      setCurrentUser(null); 
      const pathstr = routerUtility.location.pathname as string;
      console.log("clearing localstorage NA");
      localforage.clear();
      
      let page = Webpages.find((x) => x.path === pathstr);
      if ((page && page.permissionLevel > 0) || page?.mode === "Inactive") {
        routerUtility.navigateTo("/Home");
      }
    }
  };

   useEffect(()=>{
     if(user !== currentUser){
       loadUser(user);
     }
   },[user])

  //if you are not on the index page, log the page as your last visted page
  useEffect(() => {
    if ((routerUtility.location.pathname as string) !== "/" && (routerUtility.location.pathname as string) !== "/Home") {
      const strLoc = routerUtility.location.pathname as string;
      routerUtility.setCache(undefined, { name: "lastPage", data: strLoc });
      // localforage.setItem('lastPage', routerUtility.location.pathname);
    }
  }, [routerUtility.location]);

  const availWebPages = useMemo(() => 
    Webpages.filter(
      (page) =>
        page.active &&
        page.ID >= 0 
        // && page.permissionLevel <= 0 + currentReactAccount.PERMISSIONS
    ),
    [user]
  );

  const menuHeader = useMemo(() =>{
    const found = Webpages.find((page) => matchPath(page.path, routerUtility.location.pathname) !== null)
    if(found){
      return found.mode;
    }else{
      return "Open Menu"
    }
    }, [routerUtility.location]
  );

  return (
    <div className="overflow-hidden h-screen w-screen">
      {/* user profile init */}
      <UserProfileSlideover
        isOpen={openUserProfile}
        onClose={() => setOpenUserProfile(false)}
        onSubmit={() => console.log("profile submitted: ")}
      />

      {/* title bar*/}
      <header className="flex flex-row bg-emerald-700 justify-between w-full py-1 px-2 shadow">
        <p className="text-green-500 font-bold text-left text-xl">
          {websiteTitle}
        </p>

        <div
          className="flex flex-row rounded-md bg-emerald-900 px-2 cursor-pointer items-center"
          onClick={() => setOpenUserProfile(true)}
        >
          {user ?
            <p className="font-semibold text-green-500 capitalize pr-1">
              {user.email?.split('@')[0]} 
            </p>
          : null}
          <BiUserCircle
            size={24}
            className={"text-amber-500 hover:text-green-500"}
          />
        </div>
      </header>

      {/* nav bar */}
      <nav>
        <ul className="flex flex-row overflow-hidden bg-emerald-800 shadow-inner px-1 pt-2 space-x-1">
          {availWebPages.map((page) => (
            <li
              key={`${uuid()}`}
              className={clsx(
                "rounded-t-md text-left",
                matchPath(page.path, routerUtility.location.pathname) !== null
                  ? "bg-black text-green-500 shadow"
                  : "text-amber-500 bg-emerald-900 hover:text-green-500 shadow-inner"
              )}
            >
              <a href={page.path}>
                <p className={clsx("px-2 truncate")}>{page.mode}</p>
              </a>
            </li>
          ))}
        </ul>
      </nav>

      {/*main content*/}
      <div className="flex flex-row w-full h-full overflow-hidden">
        {/* content */}
        <div className={"flex flex-col w-full h-full bg-black overflow-hidden relative"}>
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Root;
